/* stylelint-disable color-no-hex, value-keyword-case */
$serif-font: 'IBM Plex Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Helvetica,Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol';
$sans-serif-font: 'IBM Plex Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Helvetica,Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol';
$monospace-font: courier new, Courier, monospace;
$silver: #ccc;
$alto: #ddd;
$yellow-light: #fffdcc;
$base-background-color: #f9f9fa;
$project-background-color: #e5ecf2;
$role-background-color: #9cb6c8;
$base-font-color: #272733;
$base-font-color-body: #575757;
$gallery: #f0f0f0;
$gray-headline: #666;
$ambassador-red: #ff4329;
$kanye-pink: #ed1c24;
$black: #000;
$white: #fff;
$emperor: #555;
$tundora: #444;
$dusty-gray: #999;
$linkedin-background: #0073b2;
$twitter-background: #45b0e3;
$github-backbround: #d97aa5;
$breakpoints: (
    'extra-small': 400px,
    'small': 544px,
    'medium': 768px,
    'large': 992px,
    'extra-large': 1200px
);
$breakpoints-redux: (
    'breakpoint-xl-max': 1199px,
    'breakpoint-l-max': 991px,
    'breakpoint-m-max': 767px,
    'breakpoint-s-max': 543px,
    'breakpoint-xs-max': 399px
);

$content-max-width: 1200px;

$font-size-max-input: 54px;
$font-size-extralarge: 44px;
$font-size-largemedium: 40px;
$font-size-large: 36px;
$font-size-mediumlarge: 30px;
$font-size-medium: 24px;
$font-size-small: 18px;
$font-size-text: 16px;
$font-size-navigation: 13px;
$font-size-extrasmall: 13px;
$font-size-button: 12px;
$font-weight-bold: 500;
$font-weight-medium: 300;
$font-weight-light: 200;
$font-weight-normal: 400;

@mixin breakpoint-redux ($width) {
    @if map_has_key($breakpoints-redux, $width) {
        $width: map_get($breakpoints-redux, $width);
        @media (max-width: $width) {
            @content;
        }
    }
}

@mixin breakpoint ($width, $type: min) {
    @if map_has_key($breakpoints, $width) {
        $width: map_get($breakpoints, $width);
        @if $type == max {
            $width: $width - 1px;
        }
        @media only screen and (#{$type}-width: $width) {
            @content;
        }
    }
}

@mixin image-insert-icon ($imgtouse, $height: 20px, $width: 20px, $img-position: 0 0) {
    background: url('#{$imgtouse}') #{$img-position} no-repeat;
    display: block;
    height: $height;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    width: $width;
}

@mixin opacity ($value: 1) {
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=' $value * 100 ')';
    filter: alpha(opacity = $value * 100);
    opacity: $value;
    zoom: 1;
}

@mixin position ($positioning, $top, $right, $bottom, $left) {
    bottom: $bottom;
    left: $left;
    position: $positioning;
    right: $right;
    top: $top;
}

@mixin transform ($properties) {
    -ms-transform: $properties;
    -webkit-transform: $properties;
    transform: $properties;
}

@mixin transition-animation ($prop, $time, $ease, $delay) {
    -moz-transition: $prop $time $ease $delay;
    -ms-transition: $prop $time $ease $delay;
    -o-transition: $prop $time $ease $delay;
    -webkit-transition: $prop $time $ease $delay;
    transition: $prop $time $ease $delay;
}

@mixin link-button-color ($background, $text-color, $font-size) {
    background-color: $background;
    border: 0;
    border-radius: 4px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .12);
    color: $text-color;
    cursor: pointer;
    display: inline-block;
    font-family: $sans-serif-font;
    font-size: $font-size;
    font-weight: $font-weight-medium;
    line-height: 1.4em;
    min-height: 1.6em;
    min-width: auto;
    outline: 0;
    padding: 8px 16px;
    text-decoration: none;
    transition: background .5s;

    &:hover {
        background-color: darken($background, 10%);
    }
}

@mixin button-color ($background, $text-color, $font-size) {
    background-color: $background;
    background-position: center;
    border: 0;
    border-radius: 4px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .12);
    color: $text-color;
    cursor: pointer;
    display: inline-block;
    font-family: $sans-serif-font;
    font-size: $font-size;
    font-weight: $font-weight-medium;
    line-height: 1.4em;
    min-height: 36px;
    min-width: 88px;
    outline: 0;
    padding: 8px 16px;
    text-decoration: none;
    transition: background .5s;

    &:hover {
        background: $background radial-gradient(circle, transparent 1%, $background 1%) center/15000%;
    }

    &:active {
        background-color: darken($background, 25%);
        background-size: 100%;
        transition: background 0s;
    }
}
/* stylelint-enable */

// ---
// # Front matter comment to ensure Jekyll properly reads file.
// ---

@import 'modules/typography';
@import 'modules/about';
@import 'modules/work';
@import 'modules/project';
@import 'modules/homepage';
@import 'modules/gallery';
@import 'modules/review';

figure {
    font-size: $font-size-medium;
    font-weight: $font-weight-medium;
    line-height: 1.8em;
    margin: 0 0 1em;

    blockquote {
        margin: 0;
        padding: 1em 0;

        &.third-party-quote {
            border-left: solid 2px $silver;
        }
    }

    figcaption {
        font-size: $font-size-small;
        line-height: 1.8em;
        text-align: right;
    }
}

.tagline {
    border-bottom: solid 1px $silver;
    margin: 0 auto;
    position: relative;
    width: 92%;

    h1 {
        color: $base-font-color;
        display: inline-block;
        font-size: $font-size-extralarge;
        margin: 20px 0;
        text-align: left;
        vertical-align: middle;

        ins {
            @include image-insert-icon ('/images/fleming-headshot-warm.jpg', 90px, 90px, 0 0);
            background-color: $white;
            background-size: cover;
            border: solid 1px $silver;
            border-radius: 50%;
            display: inline-block;
            font-size: $font-size-text;
            margin: 0 1em 0 0;
            text-decoration: none;
            vertical-align: middle;
        }

        span {
            display: inline-block;
            text-transform: uppercase;
            vertical-align: top;
        }
    }

    .soc {
        @include position (absolute, 50%, 0, auto, auto);
        list-style: none outside none;
        margin: 0;
        overflow: hidden;
        padding: 0;
        transform: translateY(-50%);

        li {
            display: inline-block;
            margin: 0 10px;

            a {
                @include transition-animation (background-color, 200ms, ease-in,  0ms);
                background-color: $gray-headline;
                border-radius: 50%;
                color: $white;
                display: block;
                font-size: $font-size-medium;
                padding: .4em;
            }

            &.linkedin a {
                background-color: $linkedin-background;

                &:hover {
                    background-color: darken($linkedin-background, 5%);
                    color: $base-background-color;
                }
            }

            &.github a {
                background-color: $github-backbround;

                &:hover {
                    background-color: darken($github-backbround, 5%);
                    color: $base-background-color;
                }
            }
        }
    }

    @include breakpoint-redux (breakpoint-l-max) {
        h1 {
            font-size: $font-size-largemedium;

            ins {
                height: 75px;
                margin: 0 .5em 0 0;
                width: 75px;
            }
        }

        .soc {
            li {
                margin: 10px 5px 0;

                a {
                    font-size: $font-size-small;
                }
            }
        }
    }

    @include breakpoint-redux (breakpoint-s-max) {
        h1 {
            margin: 20px 0 0;

            ins {
                display: none;
            }
        }

        .soc {
            display: none;
        }
    }

    @include breakpoint-redux (breakpoint-xs-max) {
        h1 {
            font-size: $font-size-mediumlarge;
        }
    }
}

.main-nav {
    font-family: $serif-font;
    margin: -40px 0 20px 110px;
    overflow: hidden;

    ul {
        display: inline-block;
        margin: 0;
        overflow: hidden;
        padding: 0;

        li {
            display: inline-block;
            margin: 0 10px;

            &:nth-child(even) {
                margin: 0;
            }

            a {
                font-size: $font-size-navigation;
                font-weight: $font-weight-medium;
                letter-spacing: 1px;
                text-transform: uppercase;
            }

            &.active {
                a {
                    color: $tundora;
                    text-decoration: none;
                }
            }
        }
    }

    @include breakpoint-redux (breakpoint-l-max) {
        margin: -40px 0 20px 90px;
    }

    @include breakpoint-redux (breakpoint-s-max) {
        margin: 0 0 10px;

        ul {
            line-height: 30px;

            li {
                &:first-of-type {
                    margin: 0 10px 0 5px;
                }

                a {
                    font-size: $font-size-extrasmall;
                }
            }
        }
    }
}

p {
    .twinemark_icon,
    .pageguide_icon,
    .burndown_icon {
        border-radius: 50%;
        float: left;
        margin: 0 2em 0 0;
    }

    .twinemark_icon {
        background-color: $white;
        font-size: 90px;
        margin: 0 2rem 0 0;
        padding: 15px;
    }

    .pageguide_icon {
        @include image-insert-icon ('/images/projects/pageguide/pageguide_1.png', 120px, 120px, 12px 34px);
        background-color: $white;
    }

    .burndown_icon {
        @include image-insert-icon ('/images/projects/burndown/burndown_1.png', 120px, 120px, 0 16px);
        background-color: $white;
    }
}

.color-block {
    background-color: $white;
    padding: 2.5% 0;

    .color-block_content {
        margin: 0 auto;
        max-width: $content-max-width;
        width: 85%;

        .social-link {
            a {
                text-decoration: none;

                .linkedin {
                    color: $linkedin-background;
                    display: inline-block;
                    height: 1.8em;
                    margin-bottom: .2em;
                    vertical-align: middle;
                    width: auto;
                }

                &:hover {
                    ins {
                        text-decoration: none;
                    }
                }
            }
        }

        @include breakpoint (extra-large, min) {
            width: 85%;
        }

        @include breakpoint (small, max) {
            width: 85%;
        }

        @include breakpoint (extra-small, max) {
            width: 90%;
        }
    }

    &.color-block_project {
        background-color: $project-background-color;
    }

    &.color-block_role {
        background-color: $role-background-color;
    }

    &.color-block_twineimage {
        background: $white url('/images/projects/twine/twine-patient-profile.jpg') center center no-repeat;
        background-size: cover;
        min-height: 425px;

        .color-block_content {
            p {
                float: right;
                font-size: $font-size-medium;
                vertical-align: middle;
                width: 50%;
            }
        }

        @include breakpoint-redux (breakpoint-xl-max) {
            .color-block_content {
                p {
                    width: 70%;
                }
            }
        }

        @include breakpoint-redux (breakpoint-s-max) {
            .color-block_content {
                p {
                    width: 80%;
                }
            }
        }

        @include breakpoint-redux (breakpoint-xs-max) {
            .color-block_content {
                p {
                    width: 100%;
                }
            }
        }
    }
}

.youtube-trailer {
    margin: 0 auto;
    padding: 5% 0;
    width: 85%;

    .videoWrapper {
        height: 0;
        padding-bottom: 56.25%; /* 16:9 */
        padding-top: 25px;
        position: relative;

        iframe {
            @include position (absolute, 0, 0, 0, 0);
            height: 100%;
            width: 100%;
        }
    }

    @include breakpoint (extra-large, min) {
        width: 60%;
    }

    @include breakpoint (small, max) {
        width: 85%;
    }

    @include breakpoint (extra-small, max) {
        width: 90%;
    }
}

/* stylelint-disable color-no-hex, function-comma-space-after, declaration-colon-space-after, function-parentheses-newline-inside, function-parentheses-space-inside  */
.colors {
    @include position (fixed, 0, 0, auto, 0);
    background-color: $white;
    background-image:
        linear-gradient(90deg,
        #fcfe04 8.33%,
        #640264 8.33%,
        #640264 16.66%,
        #fc6604 16.66%,
        #fc6604 24.99%,
        #046664 24.99%,
        #046664 33.32%,
        #cc0204 33.32%,
        #cc0204 41.65%,
        #9c0264 41.65%,
        #9c0264 49.98%,
        #fc9a04 49.98%,
        #fc9a04 58.31%,
        #040264 58.31%,
        #040264 66.64%,
        #fc3204 66.64%,
        #fc3204 74.97%,
        #64ca19 74.97%,
        #64ca19 83.3%,
        #339904 83.3%,
        #339904 91.63%,
        #05339c 91.63%
    );
    background-repeat: no-repeat;
    display: block;
    height: 2px;
    overflow: hidden;
}
/* stylelint-enable */
footer {
    clear: both;
    color: $gray-headline;
    display: none;
    font-family: $sans-serif-font;
    font-size: $font-size-extrasmall;
    font-weight: $font-weight-medium;
    margin: 50px 5%;
    overflow: hidden;
    text-align: center;
    text-transform: uppercase;

    .footer-content {
        border-top: solid 1px $silver;
        display: inline-block;
        padding: 15px 2.5%;

        ins {
            text-decoration: none;
        }

        span {
            color: $dusty-gray;
            text-decoration: none;
        }

        @include breakpoint-redux (breakpoint-s-max) {
            ins {
                display: none;
            }

            span {
                display: block;
                line-height: 1.5em;
                margin: 10px 0 0;
            }
        }
    }
}
