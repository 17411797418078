/* stylelint-disable color-no-hex, value-keyword-case */
$serif-font: 'IBM Plex Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Helvetica,Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol';
$sans-serif-font: 'IBM Plex Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Helvetica,Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol';
$monospace-font: courier new, Courier, monospace;
$silver: #ccc;
$alto: #ddd;
$yellow-light: #fffdcc;
$base-background-color: #f9f9fa;
$project-background-color: #e5ecf2;
$role-background-color: #9cb6c8;
$base-font-color: #272733;
$base-font-color-body: #575757;
$gallery: #f0f0f0;
$gray-headline: #666;
$ambassador-red: #ff4329;
$kanye-pink: #ed1c24;
$black: #000;
$white: #fff;
$emperor: #555;
$tundora: #444;
$dusty-gray: #999;
$linkedin-background: #0073b2;
$twitter-background: #45b0e3;
$github-backbround: #d97aa5;
$breakpoints: (
    'extra-small': 400px,
    'small': 544px,
    'medium': 768px,
    'large': 992px,
    'extra-large': 1200px
);
$breakpoints-redux: (
    'breakpoint-xl-max': 1199px,
    'breakpoint-l-max': 991px,
    'breakpoint-m-max': 767px,
    'breakpoint-s-max': 543px,
    'breakpoint-xs-max': 399px
);

$content-max-width: 1200px;

$font-size-max-input: 54px;
$font-size-extralarge: 44px;
$font-size-largemedium: 40px;
$font-size-large: 36px;
$font-size-mediumlarge: 30px;
$font-size-medium: 24px;
$font-size-small: 18px;
$font-size-text: 16px;
$font-size-navigation: 13px;
$font-size-extrasmall: 13px;
$font-size-button: 12px;
$font-weight-bold: 500;
$font-weight-medium: 300;
$font-weight-light: 200;
$font-weight-normal: 400;

@mixin breakpoint-redux ($width) {
    @if map_has_key($breakpoints-redux, $width) {
        $width: map_get($breakpoints-redux, $width);
        @media (max-width: $width) {
            @content;
        }
    }
}

@mixin breakpoint ($width, $type: min) {
    @if map_has_key($breakpoints, $width) {
        $width: map_get($breakpoints, $width);
        @if $type == max {
            $width: $width - 1px;
        }
        @media only screen and (#{$type}-width: $width) {
            @content;
        }
    }
}

@mixin image-insert-icon ($imgtouse, $height: 20px, $width: 20px, $img-position: 0 0) {
    background: url('#{$imgtouse}') #{$img-position} no-repeat;
    display: block;
    height: $height;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    width: $width;
}

@mixin opacity ($value: 1) {
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=' $value * 100 ')';
    filter: alpha(opacity = $value * 100);
    opacity: $value;
    zoom: 1;
}

@mixin position ($positioning, $top, $right, $bottom, $left) {
    bottom: $bottom;
    left: $left;
    position: $positioning;
    right: $right;
    top: $top;
}

@mixin transform ($properties) {
    -ms-transform: $properties;
    -webkit-transform: $properties;
    transform: $properties;
}

@mixin transition-animation ($prop, $time, $ease, $delay) {
    -moz-transition: $prop $time $ease $delay;
    -ms-transition: $prop $time $ease $delay;
    -o-transition: $prop $time $ease $delay;
    -webkit-transition: $prop $time $ease $delay;
    transition: $prop $time $ease $delay;
}

@mixin link-button-color ($background, $text-color, $font-size) {
    background-color: $background;
    border: 0;
    border-radius: 4px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .12);
    color: $text-color;
    cursor: pointer;
    display: inline-block;
    font-family: $sans-serif-font;
    font-size: $font-size;
    font-weight: $font-weight-medium;
    line-height: 1.4em;
    min-height: 1.6em;
    min-width: auto;
    outline: 0;
    padding: 8px 16px;
    text-decoration: none;
    transition: background .5s;

    &:hover {
        background-color: darken($background, 10%);
    }
}

@mixin button-color ($background, $text-color, $font-size) {
    background-color: $background;
    background-position: center;
    border: 0;
    border-radius: 4px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .12);
    color: $text-color;
    cursor: pointer;
    display: inline-block;
    font-family: $sans-serif-font;
    font-size: $font-size;
    font-weight: $font-weight-medium;
    line-height: 1.4em;
    min-height: 36px;
    min-width: 88px;
    outline: 0;
    padding: 8px 16px;
    text-decoration: none;
    transition: background .5s;

    &:hover {
        background: $background radial-gradient(circle, transparent 1%, $background 1%) center/15000%;
    }

    &:active {
        background-color: darken($background, 25%);
        background-size: 100%;
        transition: background 0s;
    }
}
/* stylelint-enable */


svg {
    -moz-box-sizing: content-box !important;
    -webkit-box-sizing: content-box !important;
    box-sizing: content-box !important;
    display: block;
    fill: currentColor;
    height: 100%;
    line-height: 1;
    overflow: visible;
    padding: 0;
    width: 100%;

    &:not(:root) {
        overflow: visible;
    }
}
