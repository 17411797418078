/* stylelint-disable color-no-hex, value-keyword-case */
/* stylelint-enable */
.highlight {
  border-bottom: 1px dashed #272733;
  padding: 0 0.2em;
}

.tooltip {
  cursor: text;
  display: inline-block;
  line-height: 28px;
  position: relative;
}
.tooltip ins {
  bottom: 2em;
  left: 2px;
  position: absolute;
  right: auto;
  top: auto;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=" 0 ")";
  filter: alpha(opacity=0);
  opacity: 0;
  zoom: 1;
  border: 3px solid #fffb99;
  box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.5);
  content: "";
  display: block;
  margin: 0;
  z-index: -1;
}
.tooltip ins::after {
  bottom: -8px;
  left: 5px;
  position: absolute;
  right: auto;
  top: auto;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #fffb99;
  content: "";
  display: block;
  margin-left: -5px;
}
.tooltip ins.lyndsey {
  background: url("/images/lyndsey.jpg") 0 0 no-repeat;
  display: block;
  height: 176px;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  width: 176px;
}
.tooltip ins.tunga {
  background: url("/images/tunga.jpg") 0 0 no-repeat;
  display: block;
  height: 220px;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  width: 165px;
}
.tooltip ins.boston {
  background: url("/images/medford.png") 0 0 no-repeat;
  display: block;
  height: 220px;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  width: 176px;
}
.tooltip ins.coffee {
  background: url("/images/coffee.jpg") 0 0 no-repeat;
  display: block;
  height: 220px;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  width: 176px;
}
.tooltip ins.lyndsey, .tooltip ins.tunga, .tooltip ins.boston, .tooltip ins.coffee {
  background-size: contain;
  overflow: visible;
}
.tooltip:hover {
  border-bottom-color: #ed1c24;
}
.tooltip:hover ins {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=" 100 ")";
  filter: alpha(opacity=100);
  opacity: 1;
  zoom: 1;
  z-index: 100;
}