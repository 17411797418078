.novacredit-portfolio-review {
    input {
        display: none;
    }

    h1 {
        font-size: $font-size-medium;
        margin: 20px 0 0;
    }

    h2 {
        color: $base-font-color;
        font-size: $font-size-medium;
    }

    .logo {
        height: 100px;
        position: relative;

        svg {
            @include position (absolute, 50%, auto, auto, 50%);
            @include transform (translate3d(-50%, -50%, 0));
            color: transparent;
            height: 85px;
            width: auto;
        }
    }

    .tabs {
        list-style: none;
        margin: 10px 0 15px;
        padding: 0;
        text-align: center;
        white-space: nowrap;

        li {
            display: inline-block;

            label {
                -webkit-appearance: none;
                border-bottom: solid 2px $white;
                color: $base-font-color;
                cursor: pointer;
                display: flex;
                flex: 1 0 auto;
                font-family: $sans-serif-font;
                font-size: .875rem;
                font-weight: $font-weight-medium;
                justify-content: center;
                letter-spacing: .089em;
                line-height: 2.25rem;
                margin: 0;
                min-height: 1.6em;
                min-width: auto;
                outline: 0;
                padding: 0 24px;
                text-align: center;
                text-decoration: none;
                text-transform: uppercase;
                transition: background .5s;
                white-space: nowrap;

                &:hover {
                    background-color: rgba($ambassador-red, .08);
                }
            }
        }
    }

    .project {
        display: none;
        margin-bottom: 250px;

        img {
            border: solid 1px $alto;
            display: block;
            margin: 0 auto 20px;
            max-width: 1200px;
            width: 80vw;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                display: none;
                position: relative;
            }
        }
    }

    #review1:checked {
        & ~ ul li:nth-child(1) label {
            border-bottom-color: $ambassador-red;
            color: $ambassador-red;
        }

        & ~ .project.review1 {
            display: block;
        }
    }

    #review2:checked {
        & ~ ul li:nth-child(2) label {
            border-bottom-color: $ambassador-red;
            color: $ambassador-red;
        }

        & ~ .project.review2 {
            display: block;
        }
    }

    #review3:checked {
        & ~ ul li:nth-child(3) label {
            border-bottom-color: $ambassador-red;
            color: $ambassador-red;
        }

        & ~ .project.review3 {
            display: block;
        }
    }

    #review1image1:checked,
    #review2image1:checked,
    #review3image1:checked {
        & ~ ul {
            li:nth-child(1) {
                display: block;
            }
        }
    }

    .featured-prev-next-control {
        @include transition-animation (opacity, 250ms, ease-in-out, 0ms);
        height: 40px;
        pointer-events: none;
        text-align: center;

        .arrows {
            display: inline-block;

            label.arrow {
                @include transition-animation (background, 200ms, ease-in,  0ms);
                background-color: rgba($ambassador-red, .08);
                border-radius: 5px;
                cursor: pointer;
                display: inline-block;
                height: 30px;
                margin: 0 10px;
                overflow: hidden;
                padding: 10px;
                pointer-events: all;
                position: relative;
                vertical-align: middle;
                width: 30px;

                &:hover {
                    background-color: rgba($ambassador-red, .22);
                }

                &:active {
                    color: $ambassador-red;
                }

                img {
                    display: block;
                    height: 100%;
                    margin: 0 auto;
                }

                &.mobile {
                    img {
                        border: solid 10px;
                    }
                }

                .arrow-left,
                .arrow-right {
                    @include position (absolute, 50%, auto, auto, 50%);
                    @include transform (translate3d(-50%, -50%, 0));
                    height: 28px;
                    width: auto;
                }

                .arrow-left {
                    margin-left: -2px;
                }
            }

            label.featured-dot-control {
                @include transition-animation (background-color, 250ms, ease-in-out, 0ms);
                background-color: $ambassador-red;
                border-radius: 50%;
                cursor: not-allowed;
                display: inline-block;
                height: 11px;
                margin: 12px 5px;
                pointer-events: all;
                vertical-align: middle;
                width: 11px;

                &:hover {
                    background-color: $ambassador-red;
                }
            }
        }

        button {
            display: inline-block;
        }
    }

    @include breakpoint-redux (breakpoint-m-max) {
        .tabs {
            li {
                label {
                    font-size: .75em;
                    padding: 0 12px;
                }
            }
        }

        .featured-prev-next-control {
            max-width: 94vw;
        }

        .project img {
            width: 94vw;
        }
    }
}
