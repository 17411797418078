.css-tabs {
    $num-of-featured: 20;
    margin: 0 auto;
    margin-top: 10px;
    max-height: 80vh;
    max-width: 100%;
    padding-bottom: 20px;
    position: relative;
    text-align: center;

    input[type='radio'] {
        @include position (absolute, -1000rem, auto, auto, -100rem);
    }

    .featured-prev-next-control {
        @include transition-animation (opacity, 250ms, ease-in-out, 0ms);
        height: 60px;
        pointer-events: none;
        text-align: center;
        z-index: 1;

        @include breakpoint (medium, max) {
            height: 50px;
        }

        .arrows {
            display: none;

            label {
                @include transition-animation (background, 200ms, ease-in,  0ms);
                background-color: rgba($kanye-pink, .08);
                border-radius: 5px;
                cursor: pointer;
                display: inline-block;
                height: 30px;
                margin: 0 10px;
                overflow: hidden;
                padding: 10px;
                pointer-events: all;
                position: relative;
                vertical-align: middle;
                width: 30px;

                &:hover {
                    background-color: rgba($kanye-pink, .22);
                }

                &:active {
                    color: $kanye-pink;
                }

                img {
                    display: block;
                    height: 100%;
                    margin: 0 auto;
                }

                &.mobile {
                    img {
                        border: solid 10px;
                    }
                }

                .arrow-left,
                .arrow-right {
                    @include position (absolute, 50%, auto, auto, 50%);
                    @include transform (translate3d(-50%, -50%, 0));
                    height: 28px;
                    width: auto;
                }

                .arrow-left {
                    margin-left: -2px;
                }
            }
        }
    }

    .tab-content {
        display: none;
        margin: 30px auto;
        padding: 10px;
        position: relative;
        text-align: center;

        img {
            box-shadow: 0 1px 20px 1px rgba(0, 0, 0, .7);
            display: block;
            margin: 0 auto;
            max-height: 80vh;
            max-width: 100%;
        }

        &.mobile {
            img {
                box-shadow: 0 1px 20px 1px rgba(0, 0, 0, 0);
            }
        }

        &.description {
            text-align: left;
        }
    }

    #tab1:checked ~ #tab-content1,
    #tab2:checked ~ #tab-content2,
    #tab3:checked ~ #tab-content3,
    #tab4:checked ~ #tab-content4,
    #tab5:checked ~ #tab-content5,
    #tab6:checked ~ #tab-content6,
    #tab7:checked ~ #tab-content7,
    #tab8:checked ~ #tab-content8,
    #tab9:checked ~ #tab-content9,
    #tab10:checked ~ #tab-content10,
    #tab11:checked ~ #tab-content11,
    #tab12:checked ~ #tab-content12,
    #tab13:checked ~ #tab-content13,
    #tab14:checked ~ #tab-content14,
    #tab15:checked ~ #tab-content15,
    #tab16:checked ~ #tab-content16,
    #tab17:checked ~ #tab-content17,
    #tab18:checked ~ #tab-content18,
    #tab19:checked ~ #tab-content19,
    #tab20:checked ~ #tab-content20 {
        display: block;
    }
    /* stylelint-disable at-rule-no-unknown */
    @for $i from 1 through $num-of-featured {
        #tab#{$i}:checked {
            & ~ .featured-prev-next-control .arrows#{$i} {
                display: block;
            }
        }
    }

    /* stylelint-enable */
    .featured-dot-control {
        padding: 12px 0;
        text-align: center;
        width: 100%;

        label {
            @include transition-animation (background-color, 250ms, ease-in-out, 0ms);
            background-color: rgba($base-font-color, .25);
            border-radius: 50%;
            cursor: pointer;
            display: inline-block;
            height: 11px;
            margin: 0 5px;
            width: 11px;

            &:hover {
                background-color: $kanye-pink;
            }
        }
    }
    /* stylelint-disable at-rule-no-unknown */
    @for $i from 1 through $num-of-featured {
        #tab#{$i}:checked {
            & ~ .featured-dot-control label:nth-child(#{$i}) {
                background-color: $kanye-pink;
            }
        }
    }
    /* stylelint-enable */
}

.project-description {
    text-align: left;
}
