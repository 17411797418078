.homepage {
    /* stylelint-disable declaration-block-no-redundant-longhand-properties */
    align-content: stretch;
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    flex-wrap: nowrap;
    justify-content: flex-start;
    /* stylelint-enable */
    margin: 2.5% auto 1rem;
    max-width: $content-max-width;
    width: 75%;

    .featured-home {
        flex: 0 1 328px;

        .project-highlight {
            position: relative;

            .iphone {
                display: block;
                height: 100%;
            }

            a {
                @include position (absolute, 49.9%, auto, auto, 50%);
                @include transform (translate3d(-50%, -50%, 0));
                display: block;
                height: 73.5%;
                overflow: hidden;
                width: 88%;

                img {
                    display: block;
                    height: 100%;
                }
            }
        }
    }

    .home-intro {
        flex: 1 1 0;
        padding: 2.5% 0 0 2.5%;
        position: relative;

        h1 {
            color: $base-font-color;
            font-size: $font-size-extralarge;
            font-weight: $font-weight-medium;
            margin: .8em 0 .2em;
            text-align: left;

            ins {
                text-decoration: none;
                white-space: nowrap;
            }

            &.headshot {
                color: $gray-headline;
                font-weight: $font-weight-light;
                text-transform: uppercase;
                white-space: nowrap;

                ins {
                    @include image-insert-icon ('/images/fleming-headshot-warm.jpg', 90px, 90px, 0 0);
                    background-color: $white;
                    background-size: cover;
                    border: solid 1px $silver;
                    border-radius: 50%;
                    display: inline-block;
                    font-size: $font-size-text;
                    margin: 0 .5em 15px 0;
                    vertical-align: middle;
                }
            }
        }

        .social-link {
            a {
                text-decoration: none;

                .linkedin {
                    color: $linkedin-background;
                    display: inline-block;
                    height: 1.8em;
                    margin-bottom: .2em;
                    vertical-align: middle;
                    width: auto;
                }

                &:hover {
                    ins {
                        text-decoration: none;
                    }
                }
            }
        }
    }

    @include breakpoint-redux (breakpoint-xl-max) {
        width: 85%;

        .home-intro {
            h1 {
                &.headshot {
                    ins {
                        display: block;
                        margin: 0 .5em 15px 0;
                    }
                }
            }
        }
    }

    @include breakpoint-redux (breakpoint-m-max) {
        display: block;
        margin: 5% auto;

        .home-intro {
            h1 {
                &.headshot {
                    text-align: center;

                    ins {
                        display: inline-block;
                    }
                }
            }
        }

        .featured-home {
            border-bottom: solid 2px $silver;
            height: 328px;
            margin: 0 auto;
            overflow: hidden;
            padding: 0 10%;
            width: 328px;
        }
    }

    @include breakpoint-redux (breakpoint-s-max) {
        .featured-home {
            height: 250px;
            width: 250px;

            .project-highlight a {
                max-height: 395px;
                top: 50%;
            }
        }

        .home-intro {
            h1 {
                font-size: $font-size-large;

                &.headshot ins {
                    display: block;
                    margin: 0 auto 15px;
                }
            }
        }
    }
}
