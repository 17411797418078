.about {
    margin: 0 auto;
    max-width: $content-max-width;
    width: 70%;

    .about-wrapper {
        /* stylelint-disable declaration-block-no-redundant-longhand-properties */
        align-content: stretch;
        align-items: flex-start;
        display: flex;
        flex-direction: row;
        flex-shrink: 0;
        flex-wrap: nowrap;
        justify-content: flex-start;
        /* stylelint-enable */
        .meedan {
            height: 1.5rem;
        }

        .description {
            flex: 1 1 100%;
            padding: 0 2%;
            vertical-align: top;

            &:nth-of-type(1) {
                padding: 0 2% 0 0;
            }

            &:nth-of-type(2) {
                padding: 0 0 0 2%;
            }
        }

        .social-link {
            a {
                text-decoration: none;

                .linkedin {
                    color: $linkedin-background;
                    display: inline-block;
                    height: 1.8em;
                    margin-bottom: .2em;
                    vertical-align: middle;
                    width: auto;
                }

                &:hover {
                    ins {
                        text-decoration: none;
                    }
                }
            }
        }
    }

    @include breakpoint-redux (breakpoint-xl-max) {
        width: 85%;
    }

    @include breakpoint-redux (breakpoint-m-max) {
        .about-wrapper {
            display: block;

            .description {
                &:nth-of-type(1),
                &:nth-of-type(2) {
                    padding: 0;
                }
            }
        }
    }
}

.footer-quote {
    background-color: $project-background-color;

    figure {
        margin: 0 auto;
        max-width: $content-max-width;
        padding: 1rem 0 3rem;
        width: 70%;

        @include breakpoint-redux (breakpoint-xl-max) {
            width: 85%;
        }
    }
}
