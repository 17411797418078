/* stylelint-disable color-no-hex, value-keyword-case */
/* stylelint-enable */
.tp-intercepts .orange {
  color: #f24e1e;
}
.tp-intercepts .green {
  color: #00c05b;
}
.tp-intercepts .blue {
  color: #06f;
}
.tp-intercepts .explanations {
  margin: 0 auto;
  width: 680px;
}
.tp-intercepts .explanations ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.tp-intercepts .explanations ul li {
  background-color: #fff;
  display: none;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.1px;
  line-height: 26px;
}
.tp-intercepts .explanations ul li:not(:empty) {
  border: solid 1px #ddd;
  border-radius: 8px;
  padding: 10px;
}
.tp-intercepts .explanations ul li:nth-of-type(1),
.tp-intercepts .regular .explanations ul li:nth-of-type(1) {
  display: block;
}
.tp-intercepts .global .explanations ul li:nth-of-type(2) {
  display: block;
}
.tp-intercepts .personal .explanations ul li:nth-of-type(3) {
  display: block;
}
.tp-intercepts .buttons {
  padding: 10px;
  margin: 0 auto;
  text-align: center;
  width: 500px;
}
.tp-intercepts .buttons ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.tp-intercepts .buttons ul li {
  display: inline-block;
  margin: 0 10px;
}
.tp-intercepts button {
  background-color: #ed1c24;
  border: 0;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 11px;
  font-weight: normal;
  padding: 10px 14px;
  transition: background-color 200ms linear 0ms;
  transition-property: background-color, color, border-color;
  text-transform: uppercase;
}
.tp-intercepts button:hover,
.tp-intercepts button:active {
  background-color: rgb(174.6510204082, 13.8489795918, 20.0040816327);
}
.tp-intercepts .vizualization {
  height: 500px;
  width: 700px;
  margin: 0 auto;
  padding: 0;
  position: relative;
}
.tp-intercepts .vizualization .animations,
.tp-intercepts .vizualization .pods,
.tp-intercepts .vizualization .paths,
.tp-intercepts .vizualization .k8s {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}
.tp-intercepts #legend-1,
.tp-intercepts #legend-2,
.tp-intercepts .paths,
.tp-intercepts #telepresence-pod-cloud {
  display: none;
}
.tp-intercepts .regular #legend-1,
.tp-intercepts .global #legend-1 {
  display: block;
}
.tp-intercepts .personal #legend-2 {
  display: block;
}
.tp-intercepts .regular #telepresence-pod-cloud,
.tp-intercepts .global #telepresence-pod-cloud,
.tp-intercepts .personal #telepresence-pod-cloud,
.tp-intercepts .regular .paths,
.tp-intercepts .global .paths,
.tp-intercepts .personal .paths {
  display: block;
}
.tp-intercepts .vizualization .pods {
  z-index: 4;
}
.tp-intercepts .vizualization .animations {
  top: -6px;
  z-index: 3;
}
.tp-intercepts .vizualization .paths {
  z-index: 2;
}
.tp-intercepts .vizualization .k8s {
  z-index: 1;
}
.tp-intercepts .vizualization .paths {
  height: calc(100% - 6px);
}
.tp-intercepts .vizualization .paths path {
  fill: #8091a9;
  fill: #7874AA;
  fill: #0D5F80;
}
.tp-intercepts .arc,
.tp-intercepts .arc-laptop-1,
.tp-intercepts .arc-laptop-2,
.tp-intercepts .linear-user,
.tp-intercepts .linear-user-1,
.tp-intercepts .linear-user-2,
.tp-intercepts .linear-orders,
.tp-intercepts .linear-laptop-1,
.tp-intercepts .linear-laptop-2,
.tp-intercepts .api,
.tp-intercepts .users,
.tp-intercepts .orders {
  fill: #06f;
  transform-box: fill-box;
  transform-origin: 50% 50%;
  visibility: hidden;
}
.tp-intercepts .arc-laptop-1,
.tp-intercepts .linear-user-1,
.tp-intercepts .linear-laptop-1 {
  fill: #f24e1e;
}
.tp-intercepts .personal .arc-laptop-2,
.tp-intercepts .personal .linear-user-2,
.tp-intercepts .personal .linear-laptop-2 {
  fill: #00c05b;
}
.tp-intercepts .regular .arc,
.tp-intercepts .global .arc,
.tp-intercepts .personal .arc {
  animation: 0.75s ease-in 0s 1 normal forwards running kf-arc;
  transform: translateX(66px) translateY(42px);
}
.tp-intercepts .personal .arc-laptop-1 {
  animation: 0.75s ease-in 0.25s 1 normal forwards running kf-arc;
  transform: translateX(66px) translateY(42px);
}
.tp-intercepts .personal .arc-laptop-2 {
  animation: 0.75s ease-in 0.5s 1 normal forwards running kf-arc;
  transform: translateX(66px) translateY(42px);
}
.tp-intercepts .regular .linear-user,
.tp-intercepts .global .linear-user,
.tp-intercepts .personal .linear-user {
  animation: 0.75s linear 0.9s 1 normal forwards running kf-user;
  transform: translateX(270px) translateY(154px);
}
.tp-intercepts .personal .linear-user-1 {
  animation: 0.75s linear 1.2s 1 normal forwards running kf-user;
  transform: translateX(270px) translateY(154px);
}
.tp-intercepts .personal .linear-user-2 {
  animation: 0.75s linear 1.5s 1 normal forwards running kf-user;
  transform: translateX(270px) translateY(154px);
}
.tp-intercepts .regular .linear-orders,
.tp-intercepts .personal .linear-orders {
  animation: 0.75slinear 0.9s 1 normal forwards running kf-orders;
  transform: translateX(286px) translateY(190px);
}
.tp-intercepts .personal .linear-laptop-1 {
  animation: 0.75s linear 1.2s 1 normal forwards running kf-laptop-1;
  transform: translateX(350px) translateY(311px);
}
.tp-intercepts .global .linear-laptop-2 {
  animation: 0.75s linear 0.9s 1 normal forwards running kf-laptop-2;
  transform: translateX(282px) translateY(186px);
}
.tp-intercepts .personal .linear-laptop-2 {
  animation: 0.75s linear 1.5s 1 normal forwards running kf-laptop-2;
  transform: translateX(282px) translateY(186px);
}
.tp-intercepts #telepresence-pod-laptop-1,
.tp-intercepts #telepresence-pod-laptop-2,
.tp-intercepts #telepresence-path-laptop-1,
.tp-intercepts #telepresence-path-laptop-2,
.tp-intercepts .regular .linear-laptop {
  display: none;
}
.tp-intercepts .global #telepresence-pod-laptop-2,
.tp-intercepts .global #telepresence-path-laptop-2 {
  display: block;
}
.tp-intercepts .global #telepresence-path-orders,
.tp-intercepts .global #telepresence-path-laptop-1,
.tp-intercepts .global #telepresence-pod-laptop-1,
.tp-intercepts .global .orders,
.tp-intercepts .global .linear-orders {
  display: none;
}
.tp-intercepts .personal #telepresence-path-orders,
.tp-intercepts .personal #telepresence-pod-laptop-1,
.tp-intercepts .personal #telepresence-pod-laptop-2,
.tp-intercepts .personal #telepresence-path-laptop-1,
.tp-intercepts .personal #telepresence-path-laptop-2 {
  display: block;
}
.tp-intercepts .personal .linear-laptop {
  fill: #f24e1e;
}
.tp-intercepts #telepresence-pod-orders {
  transition: opacity 1000ms linear 0ms;
}
.tp-intercepts .global #telepresence-pod-orders {
  opacity: 0.2;
}

@keyframes kf-arc {
  0% {
    transform: translateX(71px) translateY(50px);
    opacity: 0;
    visibility: hidden;
  }
  1% {
    transform: translateX(71px) translateY(50px);
    opacity: 1;
    visibility: visible;
  }
  9.09% {
    transform: translateX(77px) translateY(34px);
  }
  18.18% {
    transform: translateX(90px) translateY(22px);
  }
  27.27% {
    transform: translateX(108px) translateY(18px);
  }
  36.36% {
    transform: translateX(130px) translateY(25px);
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
  45.45% {
    transform: translateX(146px) translateY(44px);
  }
  54.55% {
    transform: translateX(155px) translateY(73px);
  }
  63.64% {
    transform: translateX(158px) translateY(102px);
  }
  72.73% {
    transform: translateX(158px) translateY(130px);
  }
  81.82% {
    transform: translateX(154px) translateY(165px);
  }
  90.91% {
    transform: translateX(188px) translateY(184px);
    opacity: 1;
  }
  100% {
    transform: translateX(188px) translateY(184px);
    opacity: 0;
  }
}
@keyframes kf-user {
  0% {
    transform: translateX(270px) translateY(154px);
    visibility: hidden;
    opacity: 0;
  }
  1% {
    transform: translateX(270px) translateY(154px);
    visibility: visible;
    opacity: 1;
  }
  45.45% {
    transform: translateX(308px) translateY(112px);
    opacity: 1;
  }
  54.55% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: translateX(308px) translateY(112px);
  }
}
@keyframes kf-orders {
  0% {
    transform: translateX(288px) translateY(194px);
    visibility: hidden;
    opacity: 0;
  }
  1% {
    transform: translateX(288px) translateY(194px);
    visibility: visible;
    opacity: 1;
  }
  45.45% {
    transform: translateX(404px) translateY(170px);
    opacity: 1;
  }
  54.55% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: translateX(404px) translateY(170px);
  }
}
@keyframes kf-laptop-1 {
  0% {
    transform: translateX(288px) translateY(194px);
    opacity: 0;
    visibility: hidden;
  }
  1% {
    transform: translateX(288px) translateY(194px);
    opacity: 1;
    visibility: visible;
  }
  9.09% {
    transform: translateX(312px) translateY(189px);
  }
  18.18% {
    transform: translateX(335px) translateY(184px);
  }
  100% {
    opacity: 0;
  }
  27.27% {
    transform: translateX(341px) translateY(183px);
  }
  36.36% {
    transform: translateX(315px) translateY(236px);
  }
  45.45% {
    transform: translateX(293px) translateY(274px);
  }
  54.55% {
    transform: translateX(294px) translateY(285px);
  }
  63.64% {
    transform: translateX(321px) translateY(300px);
  }
  81.82% {
    transform: translateX(354px) translateY(319px);
  }
  90.91% {
    transform: translateX(370px) translateY(328px);
    opacity: 1;
  }
  100% {
    transform: translateX(370px) translateY(328px);
    opacity: 0;
  }
}
@keyframes kf-laptop-2 {
  0% {
    transform: translateX(288px) translateY(194px);
    opacity: 0;
    visibility: hidden;
  }
  1% {
    transform: translateX(288px) translateY(194px);
    opacity: 1;
    visibility: visible;
  }
  9.09% {
    transform: translateX(312px) translateY(189px);
  }
  18.18% {
    transform: translateX(335px) translateY(184px);
  }
  100% {
    opacity: 0;
  }
  27.27% {
    transform: translateX(340px) translateY(184px);
  }
  36.36% {
    transform: translateX(398px) translateY(216px);
  }
  45.45% {
    transform: translateX(398px) translateY(225px);
  }
  54.55% {
    transform: translateX(410px) translateY(232px);
  }
  63.64% {
    transform: translateX(426px) translateY(240px);
  }
  90.91% {
    transform: translateX(475px) translateY(269px);
    opacity: 1;
  }
  100% {
    transform: translateX(475px) translateY(269px);
    opacity: 0;
  }
}
@keyframes steps-api {
  0% {
    transform: translateX(247px) translateY(216px);
    visibility: hidden;
  }
  1% {
    visibility: visible;
  }
  9.09% {
    transform: translateX(239px) translateY(191px);
  }
  18.18% {
    transform: translateX(198px) translateY(212px);
  }
  27.27% {
    transform: translateX(245px) translateY(216px);
  }
  36.36% {
    transform: translateX(270px) translateY(182px);
  }
  100% {
    visibility: hidden;
  }
  45.45% {
    transform: translateX(215px) translateY(172px);
  }
  54.55% {
    transform: translateX(240px) translateY(161px);
  }
  63.64% {
    transform: translateX(250px) translateY(159px);
  }
  72.73% {
    transform: translateX(237px) translateY(162px);
  }
  81.82% {
    transform: translateX(229px) translateY(174px);
  }
  90.91% {
    transform: translateX(240px) translateY(162px);
    visibility: visible;
  }
}
@keyframes steps-users {
  0% {
    transform: translateX(298px) translateY(83px);
    visibility: hidden;
  }
  1% {
    visibility: visible;
  }
  9.09% {
    transform: translateX(337px) translateY(104px);
  }
  18.18% {
    transform: translateX(332px) translateY(39px);
  }
  27.27% {
    transform: translateX(371px) translateY(63px);
  }
  36.36% {
    transform: translateX(308px) translateY(29px);
  }
  100% {
    visibility: hidden;
  }
  45.45% {
    transform: translateX(337px) translateY(24px);
  }
  54.55% {
    transform: translateX(364px) translateY(23px);
  }
  63.64% {
    transform: translateX(354px) translateY(71px);
  }
  72.73% {
    transform: translateX(338px) translateY(104px);
  }
  81.82% {
    transform: translateX(325px) translateY(19px);
  }
  90.91% {
    transform: translateX(298px) translateY(83px);
    visibility: visible;
  }
}
@keyframes steps-orders {
  0% {
    transform: translateX(398px) translateY(144px);
    visibility: hidden;
  }
  1% {
    visibility: visible;
  }
  9.09% {
    transform: translateX(433px) translateY(138px);
  }
  18.18% {
    transform: translateX(463px) translateY(79px);
  }
  27.27% {
    transform: translateX(422px) translateY(79px);
  }
  36.36% {
    transform: translateX(452px) translateY(149px);
  }
  100% {
    visibility: hidden;
  }
  45.45% {
    transform: translateX(472px) translateY(88px);
  }
  54.55% {
    transform: translateX(430px) translateY(97px);
  }
  63.64% {
    transform: translateX(410px) translateY(130px);
  }
  72.73% {
    transform: translateX(421px) translateY(158px);
  }
  81.82% {
    transform: translateX(433px) translateY(84px);
  }
  90.91% {
    transform: translateX(398px) translateY(144px);
    visibility: visible;
  }
}