.work-list {
    section.color-block {
        h2 {
            text-align: left;
            margin-bottom: 0;
        }

        .project-link {
            @include link-button-color ($kanye-pink, $white, $font-size-text);
        }

        ul {
            display: grid;
            grid-gap: 20px;
            grid-template-columns: repeat(3, 1fr);
            list-style: none;
            overflow: hidden;
            padding: 0;

            li {
                display: block;
                overflow: hidden;
                position: relative;

                a {
                    @include transition-animation (border-color, 200ms, ease-in,  0ms);
                    background: $gallery;
                    border: solid 2px $white;
                    display: block;

                    &:hover {
                        border: solid 2px $kanye-pink;
                    }

                    img {
                        display: block;
                        height: auto;
                        width: 100%;
                    }
                }

                &.mobile a {
                    img {
                        margin: 5px auto 0;
                    }
                }
            }
        }
    }
}

.double-flex {
    /* stylelint-disable declaration-block-no-redundant-longhand-properties */
    align-content: stretch;
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    flex-wrap: nowrap;
    justify-content: flex-start;
    /* stylelint-enable */
    .double-left,
    .double-right {
        flex: 1 1 50%;
    }

    .double-left {
        padding: 0 2.5% 0 0;
    }

    .double-right {
        border-left: solid 1px $white;
        padding: 0 0 0 2.5%;
    }

    @include breakpoint-redux (breakpoint-m-max) {
        display: block;

        .double-left,
        .double-right {
            padding: 0;
        }

        .double-right {
            border-left: 0;
            border-top: solid 5px $white;
            margin-top: 40px;
        }
    }
}

.hero-flex {
    $num-of-featured: 10;
    /* stylelint-disable declaration-block-no-redundant-longhand-properties */
    align-content: stretch;
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    flex-wrap: nowrap;
    justify-content: flex-start;

    input[type='radio'] {
        @include position (absolute, auto, auto, auto, -1000rem);
        visibility: hidden;
    }

    /* stylelint-disable at-rule-no-unknown */
    @for $i from 2 through $num-of-featured {
        #phoneTab#{$i}:checked,
        #tabletTab#{$i}:checked,
        #desktopTab#{$i}:checked {
            & ~ .hero-image .device-wrapper .screenshot-list {
                transform: translate3d(-#{($i - 1) + '00%'}, 0, 0);
            }
        }
    }

    @for $i from 1 through $num-of-featured {
        #phoneTab#{$i}:checked,
        #tabletTab#{$i}:checked,
        #desktopTab#{$i}:checked {
            & ~ .hero-content .arrows#{$i} {
                display: block;
            }

            & ~ .hero-content .description p:nth-child(#{$i}) {
                display: block;
            }

            & ~ .hero-content h3 .featured-dot-control label:nth-child(#{$i}) {
                background-color: $kanye-pink;
            }
        }
    }
    /* stylelint-enable */
    .hero-image {
        flex: 0 1 328px;

        img {
            display: block;
            height: 100%;
            width: 100%;
        }

        .ambassador-vertical-mark,
        .motif-vertical-mark {
            margin: 0 auto;
            max-height: 350px;
            max-width: 200px;
        }

        .device-wrapper {
            overflow: hidden;
            position: relative;
            z-index: 1;

            .screenshot-list {
                @include transition-animation (transform, 200ms, ease-in,  0ms);

                img {
                    @include position (absolute, 0, auto, auto, 0);
                    /* stylelint-disable at-rule-no-unknown */
                    @for $i from 2 through $num-of-featured {
                        &:nth-child(#{$i}) {
                            left: (#{($i - 1) + '00%'});
                        }
                    }
                    /* stylelint-enable */
                }
            }

            &.phone-wrapper,
            &.tablet-wrapper,
            &.desktop-wrapper {
                .iphone,
                .ipad,
                .imac {
                    display: block;
                    z-index: 2;

                    .imac-block {
                        fill: $white;
                    }
                }

                .screenshot-list {
                    z-index: -1;

                    img {
                        display: block;
                        height: 100%;
                        width: auto;
                    }
                }
            }

            &.phone-wrapper {
                .iphone {
                    height: 700px;
                }

                .screenshot-list {
                    @include position (absolute, 13.1%, 5.8%, 13.1%, 5.8%);
                    height: 73.85%;
                    max-height: 517px;
                }
            }

            &.tablet-wrapper {
                .ipad {
                    height: 700px;
                }

                .screenshot-list {
                    @include position (absolute, 9.8%, 5.2%, 9.8%, 5.2%);
                    height: 80%;
                    max-height: 560px;
                }
            }

            &.desktop-wrapper {
                .imac {
                    height: auto;
                }

                .screenshot-list {
                    @include position (absolute, 5.2%, 4%, auto, 4%);
                    height: 60%;

                    img {
                        height: auto;
                        width: 100%;
                    }
                }
            }
        }
    }

    .hero-content {
        flex: 1 1 0;
        padding: 0 2.5%;
        position: relative;

        h3 {
            color: $gray-headline;
            display: block;
            margin: 0 0 30px;
            padding: 0 0 20px;
            position: relative;

            .featured-dot-control {
                @include position (absolute, 0, 0, auto, auto);

                label {
                    @include transition-animation (background-color, 250ms, ease-in-out, 0ms);
                    background-color: rgba($base-font-color, .25);
                    border-radius: 50%;
                    cursor: pointer;
                    display: inline-block;
                    height: 11px;
                    margin: 0 5px;
                    width: 11px;

                    &:hover {
                        background-color: $kanye-pink;
                    }
                }
            }
        }

        .arrows {
            display: none;
            text-align: left;

            label {
                @include transition-animation (background, 200ms, ease-in,  0ms);
                background-color: rgba($kanye-pink, .08);
                border-radius: 5px;
                cursor: pointer;
                display: inline-block;
                height: 30px;
                margin: 0 10px 0 0;
                overflow: hidden;
                padding: 10px;
                pointer-events: all;
                position: relative;
                vertical-align: middle;
                width: 30px;

                &:hover {
                    background-color: rgba($kanye-pink, .22);
                }

                &:active {
                    color: $kanye-pink;
                }
            }
        }

        .ambassador {
            color: $black;
            height: 40px;
        }

        .description {
            margin: 30px 0 0;

            p {
                display: none;
            }
        }
    }

    &.hero-flex_reverse_desktop {
        flex-direction: row-reverse;

        .hero-image {
            flex: 0 1 450px;
        }
    }

    &.hero-flex_reverse {
        flex-direction: row-reverse;
    }

    @include breakpoint-redux (breakpoint-l-max) {
        .hero-content {
            h3 {
                .featured-dot-control {
                    @include position (relative, auto, auto, auto, auto);
                    margin: 10px 0 0 -5px;
                }
            }
        }
    }

    @include breakpoint-redux (breakpoint-m-max) {
        display: block;

        .hero-image {
            margin: 0 auto;
            width: 328px;

            .device-wrapper {
                &.phone-wrapper {
                    .screenshot-list {
                        @include position (absolute, 13.1%, 5.8%, 13.1%, 5.8%);
                        height: 73.85%;
                    }
                }
            }
        }

        .hero-content {
            margin-top: 50px;
        }

        &.hero-flex_reverse_desktop {
            .hero-image {
                width: auto;
            }
        }
    }
}

.case-study {
    .hero-image {
        border: solid 1px $alto;
        margin: 0 auto;
        max-height: 85vh;
        overflow: hidden;
        padding: 30px 0 0 0;
        position: relative;
        width: 85%;

        &::after {
            content: '';
            background: linear-gradient(0deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
            height: 10vh;
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            top: auto;
        }

        img {
            display: block;
            margin: 0 auto;
            width: 85%;
        }
    }
}
