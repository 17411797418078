code,
pre {
    font-family: $monospace-font;
}

a {
    @include transition-animation (color, 200ms, ease-in,  0ms);
    color: $kanye-pink;

    &:hover {
        color: darken($kanye-pink, 15%);
        text-decoration: none;
    }
}

h1,
h2,
h3 {
    color: $base-font-color;
    font-family: $sans-serif-font;
    font-weight: $font-weight-light;
    text-align: center;
}

h1 {
    font-size: $font-size-extralarge;
    margin: .8em .25em;

    small:nth-of-type(2) {
        color: $gray-headline;
        display: block;
        font-size: 60%;
        margin: 10px 0 0;
    }

    @include breakpoint (small, max) {
        font-size: $font-size-large;
        margin: 1.5em .6em;

        small:nth-of-type(2) {
            line-height: 1.2;
        }
    }
}

h2 {
    color: $base-font-color;
    font-size: 32px;
}

h3 {
    border-bottom: solid 1px $silver;
    color: $base-font-color;
    display: inline-block;
    font-size: $font-size-small;
    font-style: normal;
    font-weight: $font-weight-normal;
    margin: 0 0 5px;
    padding: 30px 40px 20px 0;
    text-align: left;
    text-transform: uppercase;

    small {
        font-weight: normal;
    }

    a {
        color: $base-font-color;
        text-decoration: none;

        svg.arrow-right {
            color: $kanye-pink;
        }

        &:hover {
            color: $kanye-pink;

            svg.arrow-right {
                color: $kanye-pink;
            }
        }
    }

    svg.arrow-right {
        @include transition-animation (color, 200ms, ease-in,  0ms);
        color: lighten($base-font-color, 25%);
        display: inline-block;
        height: 1em;
        margin: 0 0 0 4px;
        vertical-align: middle;
        width: auto;
    }
}

p {
    font-size: $font-size-text;
    font-weight: $font-weight-medium;
    line-height: 1.8em;

    @include breakpoint (medium, max) {
        line-height: 1.6em;
    }
}

ins.update {
    background-color: $yellow-light;
    display: inline-block;
    font-size: .8em;
    font-style: italic;
    margin: 10px 0 0 -10px;
    padding: 10px;
    text-decoration: none;
}
