/* stylelint-disable color-no-hex, value-keyword-case */
$serif-font: 'IBM Plex Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Helvetica,Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol';
$sans-serif-font: 'IBM Plex Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Helvetica,Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol';
$monospace-font: courier new, Courier, monospace;
$silver: #ccc;
$alto: #ddd;
$yellow-light: #fffdcc;
$base-background-color: #f9f9fa;
$project-background-color: #e5ecf2;
$role-background-color: #9cb6c8;
$base-font-color: #272733;
$base-font-color-body: #575757;
$gallery: #f0f0f0;
$gray-headline: #666;
$ambassador-red: #ff4329;
$kanye-pink: #ed1c24;
$black: #000;
$white: #fff;
$emperor: #555;
$tundora: #444;
$dusty-gray: #999;
$linkedin-background: #0073b2;
$twitter-background: #45b0e3;
$github-backbround: #d97aa5;
$breakpoints: (
    'extra-small': 400px,
    'small': 544px,
    'medium': 768px,
    'large': 992px,
    'extra-large': 1200px
);
$breakpoints-redux: (
    'breakpoint-xl-max': 1199px,
    'breakpoint-l-max': 991px,
    'breakpoint-m-max': 767px,
    'breakpoint-s-max': 543px,
    'breakpoint-xs-max': 399px
);

$content-max-width: 1200px;

$font-size-max-input: 54px;
$font-size-extralarge: 44px;
$font-size-largemedium: 40px;
$font-size-large: 36px;
$font-size-mediumlarge: 30px;
$font-size-medium: 24px;
$font-size-small: 18px;
$font-size-text: 16px;
$font-size-navigation: 13px;
$font-size-extrasmall: 13px;
$font-size-button: 12px;
$font-weight-bold: 500;
$font-weight-medium: 300;
$font-weight-light: 200;
$font-weight-normal: 400;

@mixin breakpoint-redux ($width) {
    @if map_has_key($breakpoints-redux, $width) {
        $width: map_get($breakpoints-redux, $width);
        @media (max-width: $width) {
            @content;
        }
    }
}

@mixin breakpoint ($width, $type: min) {
    @if map_has_key($breakpoints, $width) {
        $width: map_get($breakpoints, $width);
        @if $type == max {
            $width: $width - 1px;
        }
        @media only screen and (#{$type}-width: $width) {
            @content;
        }
    }
}

@mixin image-insert-icon ($imgtouse, $height: 20px, $width: 20px, $img-position: 0 0) {
    background: url('#{$imgtouse}') #{$img-position} no-repeat;
    display: block;
    height: $height;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    width: $width;
}

@mixin opacity ($value: 1) {
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=' $value * 100 ')';
    filter: alpha(opacity = $value * 100);
    opacity: $value;
    zoom: 1;
}

@mixin position ($positioning, $top, $right, $bottom, $left) {
    bottom: $bottom;
    left: $left;
    position: $positioning;
    right: $right;
    top: $top;
}

@mixin transform ($properties) {
    -ms-transform: $properties;
    -webkit-transform: $properties;
    transform: $properties;
}

@mixin transition-animation ($prop, $time, $ease, $delay) {
    -moz-transition: $prop $time $ease $delay;
    -ms-transition: $prop $time $ease $delay;
    -o-transition: $prop $time $ease $delay;
    -webkit-transition: $prop $time $ease $delay;
    transition: $prop $time $ease $delay;
}

@mixin link-button-color ($background, $text-color, $font-size) {
    background-color: $background;
    border: 0;
    border-radius: 4px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .12);
    color: $text-color;
    cursor: pointer;
    display: inline-block;
    font-family: $sans-serif-font;
    font-size: $font-size;
    font-weight: $font-weight-medium;
    line-height: 1.4em;
    min-height: 1.6em;
    min-width: auto;
    outline: 0;
    padding: 8px 16px;
    text-decoration: none;
    transition: background .5s;

    &:hover {
        background-color: darken($background, 10%);
    }
}

@mixin button-color ($background, $text-color, $font-size) {
    background-color: $background;
    background-position: center;
    border: 0;
    border-radius: 4px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .12);
    color: $text-color;
    cursor: pointer;
    display: inline-block;
    font-family: $sans-serif-font;
    font-size: $font-size;
    font-weight: $font-weight-medium;
    line-height: 1.4em;
    min-height: 36px;
    min-width: 88px;
    outline: 0;
    padding: 8px 16px;
    text-decoration: none;
    transition: background .5s;

    &:hover {
        background: $background radial-gradient(circle, transparent 1%, $background 1%) center/15000%;
    }

    &:active {
        background-color: darken($background, 25%);
        background-size: 100%;
        transition: background 0s;
    }
}
/* stylelint-enable */


.tp-intercepts {
    .orange {
        color: #f24e1e;
    }

    .green {
        color: #00c05b;
    }

    .blue {
        color: #06f;
    }

    .explanations {
        margin: 0 auto;
        width: 680px;
    }

    .explanations ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .explanations ul li {
        background-color: #fff;
        display: none;
        font-size: 14px;
        font-style: normal;
        font-weight: normal;
        letter-spacing: .1px;
        line-height: 26px;
    }

    .explanations ul li:not(:empty) {
        border: solid 1px $alto;
        border-radius: 8px;
        padding: 10px;
    }

    .explanations ul li:nth-of-type(1),
    .regular .explanations ul li:nth-of-type(1) {
        display: block;
    }

    .global .explanations ul li:nth-of-type(2) {
        display: block;
    }

    .personal .explanations ul li:nth-of-type(3) {
        display: block;
    }

    .buttons {
        padding: 10px;
        margin: 0 auto;
        text-align: center;
        width: 500px;
    }

    .buttons ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .buttons ul li {
        display: inline-block;
        margin: 0 10px;
    }

    button {
        background-color: $kanye-pink;
        border: 0;
        border-radius: 5px;
        color: #fff;
        cursor: pointer;
        font-size: 11px;
        font-weight: normal;
        padding: 10px 14px;
        transition: background-color 200ms linear 0ms;
        transition-property: background-color, color, border-color;
        text-transform: uppercase;
    }

    button:hover,
    button:active {
        background-color: darken($kanye-pink, 15%);
    }

    .vizualization {
        height: 500px;
        width: 700px;
        margin: 0 auto;
        padding: 0;
        position: relative;
    }

    .vizualization .animations,
    .vizualization .pods,
    .vizualization .paths,
    .vizualization .k8s {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
    }

    #legend-1,
    #legend-2,
    .paths,
    #telepresence-pod-cloud {
        display: none;
    }

    .regular #legend-1,
    .global #legend-1 {
        display: block;
    }

    .personal #legend-2 {
        display: block;
    }

    .regular #telepresence-pod-cloud,
    .global #telepresence-pod-cloud,
    .personal #telepresence-pod-cloud,
    .regular .paths,
    .global .paths,
    .personal .paths {
        display: block;
    }

    .vizualization .pods {
        z-index: 4;
    }

    .vizualization .animations {
        top: -6px;
        z-index: 3;
    }

    .vizualization .paths {
        z-index: 2;
    }

    .vizualization .k8s {
        z-index: 1;
    }

    .vizualization .paths {
        height: calc(100% - 6px);
    }

    .vizualization .paths path {
        fill: #8091a9;
        fill: #7874AA;
        fill: #0D5F80;
    }

    .arc,
    .arc-laptop-1,
    .arc-laptop-2,
    .linear-user,
    .linear-user-1,
    .linear-user-2,
    .linear-orders,
    .linear-laptop-1,
    .linear-laptop-2,
    .api,
    .users,
    .orders {
        fill: #06f;
        transform-box: fill-box;
        transform-origin: 50% 50%;
        visibility: hidden;
    }

    .arc-laptop-1,
    .linear-user-1,
    .linear-laptop-1 {
        fill: #f24e1e;
    }

    .personal .arc-laptop-2,
    .personal .linear-user-2,
    .personal .linear-laptop-2 {
        fill: #00c05b;
    }

    .regular .arc,
    .global .arc,
    .personal .arc {
        animation: .75s ease-in 0s 1 normal forwards running kf-arc;
        transform: translateX(66px) translateY(42px);
    }

    .personal .arc-laptop-1 {
        animation: .75s ease-in .25s 1 normal forwards running kf-arc;
        transform: translateX(66px) translateY(42px);
    }

    .personal .arc-laptop-2 {
        animation: .75s ease-in .5s 1 normal forwards running kf-arc;
        transform: translateX(66px) translateY(42px);
    }

    .regular .linear-user,
    .global .linear-user,
    .personal .linear-user {
        animation: .75s linear .9s 1 normal forwards running kf-user;
        transform: translateX(270px) translateY(154px);
    }

    .personal .linear-user-1 {
        animation: .75s linear 1.2s 1 normal forwards running kf-user;
        transform: translateX(270px) translateY(154px);
    }

    .personal .linear-user-2 {
        animation: .75s linear 1.5s 1 normal forwards running kf-user;
        transform: translateX(270px) translateY(154px);
    }

    .regular .linear-orders,
    .personal .linear-orders {
        animation: .75slinear .9s 1 normal forwards running kf-orders;
        transform: translateX(286px) translateY(190px);
    }

    .personal .linear-laptop-1 {
        animation: .75s linear 1.2s 1 normal forwards running kf-laptop-1;
        transform: translateX(350px) translateY(311px);
    }

    .global .linear-laptop-2 {
        animation: .75s linear .9s 1 normal forwards running kf-laptop-2;
        transform: translateX(282px) translateY(186px);
    }

    .personal .linear-laptop-2 {
        animation: .75s linear 1.5s 1 normal forwards running kf-laptop-2;
        transform: translateX(282px) translateY(186px);
    }

    #telepresence-pod-laptop-1,
    #telepresence-pod-laptop-2,
    #telepresence-path-laptop-1,
    #telepresence-path-laptop-2,
    .regular .linear-laptop {
        display: none;
    }

    .global #telepresence-pod-laptop-2,
    .global #telepresence-path-laptop-2 {
        display: block;
    }

    .global #telepresence-path-orders,
    .global #telepresence-path-laptop-1,
    .global #telepresence-pod-laptop-1,
    .global .orders,
    .global .linear-orders {
        display: none;
    }

    .personal #telepresence-path-orders,
    .personal #telepresence-pod-laptop-1,
    .personal #telepresence-pod-laptop-2,
    .personal #telepresence-path-laptop-1,
    .personal #telepresence-path-laptop-2 {
        display: block;
    }

    .personal .linear-laptop {
        fill: #f24e1e;
    }

    #telepresence-pod-orders {
        transition: opacity 1000ms linear 0ms;
    }

    .global #telepresence-pod-orders {
        opacity: .2;
    }
}

@keyframes kf-arc {
    0% {
        transform: translateX(71px) translateY(50px);
        opacity: 0;
        visibility: hidden;
    }
    1% {
        transform: translateX(71px) translateY(50px);
        opacity: 1;
        visibility: visible;
    }
    9.09% {
        transform: translateX(77px) translateY(34px);
    }
    18.18% {
        transform: translateX(90px) translateY(22px);
    }
    27.27% {
        transform: translateX(108px) translateY(18px);
    }
    36.36% {
        transform: translateX(130px) translateY(25px);
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
    45.45% {
        transform: translateX(146px) translateY(44px);
    }
    54.55% {
        transform: translateX(155px) translateY(73px);
    }
    63.64% {
        transform: translateX(158px) translateY(102px);
    }
    72.73% {
        transform: translateX(158px) translateY(130px);
    }
    81.82% {
        transform: translateX(154px) translateY(165px);
    }
    90.91% {
        transform: translateX(188px) translateY(184px);
        opacity: 1;
    }
    100% {
        transform: translateX(188px) translateY(184px);
        opacity: 0;
    }
}

@keyframes kf-user {
    0% {
        transform: translateX(270px) translateY(154px);
        visibility: hidden;
        opacity: 0;
    }
    1% {
        transform: translateX(270px) translateY(154px);
        visibility: visible;
        opacity: 1;
    }
    45.45% {
        transform: translateX(308px) translateY(112px);
        opacity: 1;
    }
    54.55% {
        opacity: 0;
    }
    100% {
        opacity: 0;
        transform: translateX(308px) translateY(112px);
    }
}

@keyframes kf-orders {
    0% {
        transform: translateX(288px) translateY(194px);
        visibility: hidden;
        opacity: 0;
    }
    1% {
        transform: translateX(288px) translateY(194px);
        visibility: visible;
        opacity: 1;
    }
    45.45% {
        transform: translateX(404px) translateY(170px);
        opacity: 1;
    }
    54.55% {
        opacity: 0;
    }
    100% {
        opacity: 0;
        transform: translateX(404px) translateY(170px);
    }
}

@keyframes kf-laptop-1 {
    0% {
        transform: translateX(288px) translateY(194px);
        opacity: 0;
        visibility: hidden;
    }
    1% {
        transform: translateX(288px) translateY(194px);
        opacity: 1;
        visibility: visible;
    }
    9.09% {
        transform: translateX(312px) translateY(189px);
    }
    18.18% {
        transform: translateX(335px) translateY(184px);
    }
    100% {
        opacity: 0;
    }
    27.27% {
        transform: translateX(341px) translateY(183px);
    }
    36.36% {
        transform: translateX(315px) translateY(236px);
    }
    45.45% {
        transform: translateX(293px) translateY(274px);
    }
    54.55% {
        transform: translateX(294px) translateY(285px);
    }
    63.64% {
        transform: translateX(321px) translateY(300px);
    }
    81.82% {
        transform: translateX(354px) translateY(319px);
    }
    90.91% {
        transform: translateX(370px) translateY(328px);
        opacity: 1;
    }
    100% {
        transform: translateX(370px) translateY(328px);
        opacity: 0;
    }
}

@keyframes kf-laptop-2 {
    0% {
        transform: translateX(288px) translateY(194px);
        opacity: 0;
        visibility: hidden;
    }
    1% {
        transform: translateX(288px) translateY(194px);
        opacity: 1;
        visibility: visible;
    }
    9.09% {
        transform: translateX(312px) translateY(189px);
    }
    18.18% {
        transform: translateX(335px) translateY(184px);
    }
    100% {
        opacity: 0;
    }
    27.27% {
        transform: translateX(340px) translateY(184px);
    }
    36.36% {
        transform: translateX(398px) translateY(216px);
    }
    45.45% {
        transform: translateX(398px) translateY(225px);
    }
    54.55% {
        transform: translateX(410px) translateY(232px);
    }
    63.64% {
        transform: translateX(426px) translateY(240px);
    }
    90.91% {
        transform: translateX(475px) translateY(269px);
        opacity: 1;
    }
    100% {
        transform: translateX(475px) translateY(269px);
        opacity: 0;
    }
}

@keyframes steps-api {
    0% {
        transform: translateX(247px) translateY(216px);
        visibility: hidden;
    }
    1% {
        visibility: visible;
    }
    9.09% {
        transform: translateX(239px) translateY(191px);
    }
    18.18% {
        transform: translateX(198px) translateY(212px);
    }
    27.27% {
        transform: translateX(245px) translateY(216px);
    }
    36.36% {
        transform: translateX(270px) translateY(182px);
    }
    100% {
        visibility: hidden;
    }
    45.45% {
        transform: translateX(215px) translateY(172px);
    }
    54.55% {
        transform: translateX(240px) translateY(161px);
    }
    63.64% {
        transform: translateX(250px) translateY(159px);
    }
    72.73% {
        transform: translateX(237px) translateY(162px);
    }
    81.82% {
        transform: translateX(229px) translateY(174px);
    }
    90.91% {
        transform: translateX(240px) translateY(162px);
        visibility: visible;
    }
}

@keyframes steps-users {
    0% {
        transform: translateX(298px) translateY(83px);
        visibility: hidden;
    }
    1% {
        visibility: visible;
    }
    9.09% {
        transform: translateX(337px) translateY(104px);
    }
    18.18% {
        transform: translateX(332px) translateY(39px);
    }
    27.27% {
        transform: translateX(371px) translateY(63px);
    }
    36.36% {
        transform: translateX(308px) translateY(29px);
    }
    100% {
        visibility: hidden;
    }
    45.45% {
        transform: translateX(337px) translateY(24px);
    }
    54.55% {
        transform: translateX(364px) translateY(23px);
    }
    63.64% {
        transform: translateX(354px) translateY(71px);
    }
    72.73% {
        transform: translateX(338px) translateY(104px);
    }
    81.82% {
        transform: translateX(325px) translateY(19px);
    }
    90.91% {
        transform: translateX(298px) translateY(83px);
        visibility: visible;
    }
}

@keyframes steps-orders {
    0% {
        transform: translateX(398px) translateY(144px);
        visibility: hidden;
    }
    1% {
        visibility: visible;
    }
    9.09% {
        transform: translateX(433px) translateY(138px);
    }
    18.18% {
        transform: translateX(463px) translateY(79px);
    }
    27.27% {
        transform: translateX(422px) translateY(79px);
    }
    36.36% {
        transform: translateX(452px) translateY(149px);
    }
    100% {
        visibility: hidden;
    }
    45.45% {
        transform: translateX(472px) translateY(88px);
    }
    54.55% {
        transform: translateX(430px) translateY(97px);
    }
    63.64% {
        transform: translateX(410px) translateY(130px);
    }
    72.73% {
        transform: translateX(421px) translateY(158px);
    }
    81.82% {
        transform: translateX(433px) translateY(84px);
    }
    90.91% {
        transform: translateX(398px) translateY(144px);
        visibility: visible;
    }
}
