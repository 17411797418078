/* stylelint-disable color-no-hex, value-keyword-case */
/* stylelint-enable */
svg {
  -moz-box-sizing: content-box !important;
  -webkit-box-sizing: content-box !important;
  box-sizing: content-box !important;
  display: block;
  fill: currentColor;
  height: 100%;
  line-height: 1;
  overflow: visible;
  padding: 0;
  width: 100%;
}
svg:not(:root) {
  overflow: visible;
}