.project.color-block {
    &.desktop {
        .color-block_content.hero-flex {
            width: 85%;

            .hero-image {
                flex: 0 1 750px;
            }

            .hero-content {
                h3 {
                    .featured-dot-control {
                        @include position (relative, auto, auto, auto, auto);
                        margin: 10px 0 0 -5px;
                    }
                }
            }
        }
    }

    &.mobile_tablet {
        .color-block_content.hero-flex {
            .hero-image {
                flex: 0 1 470px;
            }
        }
    }

    @include breakpoint-redux (breakpoint-xl-max) {
        &.desktop {
            .color-block_content {
                &.hero-flex {
                    width: 95%;

                    .hero-image {
                        flex: 0 1 550px;
                    }
                }
            }
        }
    }

    @include breakpoint-redux (breakpoint-l-max) {
        &.desktop {
            .color-block_content {
                &.hero-flex {
                    .hero-image {
                        flex: 0 1 425px;
                    }
                }
            }
        }
    }

    @include breakpoint-redux (breakpoint-m-max) {
        .color-block_content {
            &.hero-flex {
                display: block;

                .hero-image {
                    margin: 0 auto;
                    width: 328px;

                    .device-wrapper {
                        &.phone-wrapper {
                            .screenshot-list {
                                @include position (absolute, 13.1%, 5.8%, 13.1%, 5.8%);
                                height: 73.85%;
                            }
                        }

                        &.tablet-wrapper {
                            .ipad {
                                height: 486px;
                            }

                            .screenshot-list {
                                @include position (absolute, 9.8%, 5.2%, 9.8%, 5.2%);
                                height: 80.6%;
                            }
                        }
                    }
                }

                .hero-content {
                    margin-top: 50px;
                }
            }
        }
    }
}
